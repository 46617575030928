export default {

  publicPresaleFLV: {
    97: '0xF1A45556a43451E0B23d70b913FdaEd862165cAA', // testnet bsc
    56: '0xF1A45556a43451E0B23d70b913FdaEd862165cAA'  // mainnet bsc
  },
  FLV: {
    97: '0xEF7D4fb2E617404Df361a30794e2daAE1d47Bb8b',   // TESTNET BSC
    56: '0x0000000000000000000000000000000000000000',   // MAINNET BSC
  },
  NSM: {
    56: '0x337e35Ed5B38D5C7Ec9F8d7cF78fe7F43d7DEC6F',   // MAINNET BSC
    97: '0x6746767c6dF2A2bF497b4DfBbB1b0767b9946786',  // TESTNET BSC
  },
  WBNB: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',   // MAINNET BSC
    97: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd', // TESTNET BSC
  },
  WETH: {
    56:  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',   // MAINNET BSC
  },  
  PresaleFLV: {
    56: '0x78fDf2F1985118943FF8fc7d4d9702D9820d7C9D', //  MAINNET BSC
  },
  nsmPair: {
    56: '0x357f9cd8f2749A31119C3E32729965CA56f4cBd8',   // MAINNET BSC
    97: '0x73BEDe1D162c3DCD38b5a44c5637ae66D5f11785', // TESTNET BSC
  },
  nsmMigrate: {
    56: '0xC63a76B9Dc7dbad4a786969a13613a35466cA9a0',
    97: '0xC63a76B9Dc7dbad4a786969a13613a35466cA9a0',
  },
  NotToolsLPDataV3: {
    56: '0xfffff8fE58810B693DA405bEfab496E64EC1E40E',
  },
  NotToolsERC20Data: {
    56: '0xD00DAD8e9680d863bf178fC74F6F58deA1bcc34b',
  },
  NotToolsMultiSend: {
    56:'0x7117113772b3e95BeFAC2d5a183e60070465CE95',
  },
  NotToolsBalancesV2: {
    56: '0xaaaa1D1Ba4572B419584e2C30a357A890172B32C',
  },
  NotToolsLivePriceV3: {
    56: '0x4444434a5917ae1c00FE51705a8C9C4B36887cde',
  },
  NotToolsFactoryCreate2: {
    56: '0x04396f9531c5f3cd0fac735f661a987e7e08fc87',
  },
  PancakeFactoryV2: {
    56: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
  },
  PancakeRouter: {
    56: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
    97: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
  },

  SafeEarn: {
    56: '0x099f551eA3cb85707cAc6ac507cBc36C96eC64Ff',    
  },
  Mooney: {
    56: '0x98631c69602083d04f83934576a53e2a133d482f',
  },










  // START THE ORIGINAL PANCAKE SWAP ADDRESSES //
  masterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lottery: {
    97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
    56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  lotteryNFT: {
    97: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e',
    56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
}
